<template>
  <b-container id="mainContainer" align-v="center">

    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" class="blobBg">
      <path fill="#41B883"
        d="M66.2,-49.1C81.5,-33.5,86.8,-6.2,79.8,15.6C72.7,37.5,53.3,53.9,30.9,65.1C8.5,76.2,-16.9,81.9,-36.2,73.3C-55.5,64.7,-68.8,41.7,-70.8,19.9C-72.8,-2,-63.6,-22.7,-49.9,-37.8C-36.2,-53,-18.1,-62.7,3.6,-65.6C25.4,-68.5,50.8,-64.6,66.2,-49.1Z"
        transform="translate(100 100)" />
    </svg>
    <b-row align-v="center" align-h="center">
      <b-col md="6">
        <div>
          <b-card id="cardLogin" class="scale-in-bl">
            <b-form role="form" @submit.prevent="onSubmit" id="formLogin">
              <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" class="blob">
                <path fill="#41B883"
                  d="M66.2,-49.1C81.5,-33.5,86.8,-6.2,79.8,15.6C72.7,37.5,53.3,53.9,30.9,65.1C8.5,76.2,-16.9,81.9,-36.2,73.3C-55.5,64.7,-68.8,41.7,-70.8,19.9C-72.8,-2,-63.6,-22.7,-49.9,-37.8C-36.2,-53,-18.1,-62.7,3.6,-65.6C25.4,-68.5,50.8,-64.6,66.2,-49.1Z"
                  transform="translate(100 100)" />
              </svg>

              <img alt="Logo" src="/img/suonnon-logo.png" width="200" class="mb-3" />

              <b-form-group id="input-group-1">
                <b-form-input class="input" v-model="model.user" type="text" name="username" placeholder="Tên đăng nhập"
                  required></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-2">
                <b-form-input class="input" v-model="model.password" placeholder="Mật khẩu" type="password"
                  name="password" required></b-form-input>
              </b-form-group>

              <div class="d-flex justify-content-center mt-3">
                <b-button type="submit" variant="danger" class="my-4" @click="login">Đăng nhập</b-button>
              </div>
            </b-form>
          </b-card>
        </div>
      </b-col>
      <b-col md="6">
        <b-img src="/img/business.png" fluid class="businessImg scale-in-bottom" center />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      model: {
        user: "",
        password: "",
        rememberMe: false
      }
    };
  },
  mounted() {
    let body = document.body;
    body.classList.add("bg-default");
  },
  methods: {
    onSubmit() {
      return false;
      // this will be called only after form is valid. You can do api call here to login
    },
    login() {
      let vm = this;
      vm.loginError = false;
      // Loading
      // vm.$vs.loading();

      vm.$store
        .dispatch("login", {
          name: vm.model.user,
          password: vm.model.password
        })
        .then(res => {
          console.log(res);
          window.location.href = "/";
          // vm.$vs.loading.close();
        })
        .catch(err => {
          this.makeToast("Lỗi", err, "danger");
        });
    },
    makeToast(title, content, variant, append = false) {
      this.$bvToast.toast(content, {
        title: title,
        autoHideDelay: 5000,
        appendToast: append,
        variant: variant,
        solid: true,
        toaster: "b-toaster-bottom-right mb-5"
      });
    }
  }
};
</script>

<style lang="scss">
@import "../assets/scss/login-animation.scss";


#app {
  display: flex;
  align-items: center;
  min-height: 100vh;
  /* background: linear-gradient(to right, #41b883, #83c5be); */
  /* background: url('./assets/images/bg.jpg'); */
  background: linear-gradient(to bottom,
      rgba(65, 184, 131, 0.5) 0%,
      rgba(131, 197, 190, 0.5) 100%),
    url("/img/login-bg.jpg");
  background-position: center;
  background-size: cover;
  padding: 30px 0 0;
}

#mainContainer {
  width: 100%;
  height: auto;
  position: relative;
}

.blobBg {
  position: absolute;
  width: 750px;
  right: -7%;
  bottom: 0;
}

#cardLogin {
  border-radius: 15px;
  box-shadow: 0px 0px 10px #999;

  #formLogin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    position: relative;

    h1 {
      color: #41b883;
      font-weight: 700;
      // font-family: $Poppins;
    }

    h2 {
      font-size: 1.2rem;
      color: #555;
      font-weight: 600;
      // font-family: $Poppins;
    }

    .other-account {
      color: #999;
    }

    .icons {
      width: 30px;
      cursor: pointer;
    }

    .input {
      border-radius: 10px;
    }

    .remember {
      color: #555;
    }

    .blob {
      position: absolute;
      top: -15%;
      left: -12%;
      width: 150px;
      opacity: 0.2;
    }
  }

  /* .logoLogin {
    margin: 0 auto;
    width: 100px;
  } */

  .forgotPassword {
    color: #999;
    text-decoration: none;

    &:hover {
      color: #41b883;
      transition: 0.5s ease-in-out;
    }
  }

  /* .btn-login {
    width: 200px;
    background-color: #41b883;
    border: none;
    font-size: 1.3rem;
    font-weight: 600;
    border-radius: 10px;

    &:hover {
      background-color: #83c5be;
      transition: 0.5s ease-in-out;
    }
  } */

  .arrow-btn {
    width: 100px;
    border-radius: 15px;
    background: #41b883;
    color: #fff;
    font-size: 60px;
    padding: 5px;
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);

    &:hover {
      background: #83c5be;
      transition: 0.5s ease-in-out;
      box-shadow: none;
    }
  }

  .register {
    span {
      color: #555;
    }

    .createAccount {
      color: #41b883;
      text-decoration: none;
    }
  }
}
</style>